<template>
  <div class="Collapsible">
    <button
      :class="[
        'panel w-full',
        props.triggerClass,
        isDisabled ? 'pointer-events-none' : 'cursor-pointer',
      ]"
      @click="handleAccordion"
      :aria-label="ariaLabel"
      :open-label="ariaLabel"
    >
      <slot name="trigger" :isActive="isOpen" />
    </button>

    <Collapse as="section" :when="isOpen" :class="props.contentClass">
      <slot />
    </Collapse>
  </div>
</template>
<script setup>
import { Collapse } from "vue-collapsed";

const props = defineProps({
  ariaLabel: {
    type: String,
    default: "",
  },
  triggerClass: {
    type: String,
    default: "w-full",
  },
  contentClass: {
    type: String,
    default: "",
  },
  isExpanded: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  onClick: {
    type: Function,
    default: null,
  },
});

const isOpen = ref(props.isExpanded);

watch(
  props,
  (newValue, oldValue) => {
    isOpen.value = props.isExpanded;
  },
  { deep: true }
);

const handleAccordion = () => {
  isOpen.value = !isOpen.value;
  if (props.onClick) {
    props.onClick();
  }
};
const updateValue = (newVal) => {
  isOpen.value = newVal;
};

defineExpose({
  updateValue,
});
</script>